<script>
import * as moment from "moment";
import Layout from "../../layouts/main";
import VuePhoneNumberInput from "vue-phone-number-input";
import DatePicker from "vue2-datepicker";
import { required, email, decimal, minValue, maxLength } from "vuelidate/lib/validators";
import * as cloneDeep from "lodash.clonedeep";

import {
  POST_PAYMENT_LINKS,
  CHECK_REF_LINK_PAYMENT_LINKS,
  FETCH_MERCHANT_ENTITY_DETAILS
} from "@/state/actions.type";
import PaymentMethodModal from '../../../components/payment-method-modal.vue';
import Country from "../../../components/country.vue";
import Common from "../../../helpers/Common";

export const CHAIPAY_APP_URL = process.env.VUE_APP_CHAIPAY_FRONTEND_URL;
export const CHECKOUT_BASE_URL = process.env.VUE_APP_CHECKOUT_BASE_URL;
export default {
    components: {
        VuePhoneNumberInput,
        DatePicker,
        PaymentMethodModal,
        Layout,
        Country
    },
    validations: {
        chaiPayMerchantDetails: {
            amount: { required, decimal, minValue: minValue(0.01), maxLength: maxLength(10) },
            billing_details_billing_name: { maxLength: maxLength(30) },
            currency: { required },
            country: { required },
            // expiry_hours: { required },
            expiry_date: { required },
            merchant_order_id: { required },
            billing_details_billing_email: { email },
            // billing_details_billing_phone: { required },
        },
    },
    data() {
        return {
            orderLink: "",
            payment_link_ref:"",
            typesubmit: false,
            max: 200,
            add_fields:false,
            add_fields_data: [{"key": "", "value": ""}],
            add_fields_error:false,
            timeOptions: [],
            unitOptions: [],
            checkout_base_url: CHECKOUT_BASE_URL,
            phoneNumber: "",
            phoneNumberVal: null,
            datepickerOpen: false,
            expiryDatepickerOpen: false,
            send_reminder: false,
            isProcessing: false,
            chaiPayMerchantDetails: {
                amount: "",
                currency: "",
                country: "",
                expiry_date: new Date(new Date().getTime() + 2 * 24 * 3600 * 1000),
                merchant_order_id: `${(this._uid + this.$uuid.v1())
                .replace(/-/g, "")
                .substring(1, 13)
                .toUpperCase()}${this._uid}`,
                billing_details_billing_name: "",
                billing_details_billing_email: "",
                billing_details_billing_phone: "",
                description: "",
                ref_link: "",
                checked_ref_link: undefined,
                send_link: null,
                email: false,
                sms: false,
                reminder_time: "",
                reminder_time_unit: "",
                scheduled_date: "",
                success_url:"",
                failure_url:"",
            },
            selectedPaymentMethods: [],
            showCollapse: {
              customerInfo: false,
              additionalInfo: false
            },
            currencies: []
        }
    },
    filters: {
        date(value) {
            return moment(value).format("DD MMM, YYYY");
        },
        time(value) {
            return moment(value).format("hh:mm A");
        },
    },
    async created() {
        this.currencies = Common.currencyList();
        this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`)
        this.chaiPayMerchantDetails.currency = this.$route.query.lastCurrency ? this.$route.query.lastCurrency : this.convertCountryToCurrency;
        this.chaiPayMerchantDetails.country =  this.$route.query.lastCountry ?  this.$route.query.lastCountry : this.$store.state.auth.currentUser.country;
        this.chaiPayMerchantDetails.expiry_date = new Date(new Date().getTime() + 2 * 24 * 3600 * 1000);
        if(this.$route.query.pageRef) {
            let linkDetails = await cloneDeep(this.paymentLinkDetailStore).content

            this.chaiPayMerchantDetails.amount = linkDetails.amount
            this.chaiPayMerchantDetails.currency = linkDetails.currency
            this.chaiPayMerchantDetails.country = linkDetails.country_code
            this.chaiPayMerchantDetails.merchant_order_id = `${(this._uid + this.$uuid.v1()).replace(/-/g, "").substring(1, 13).toUpperCase()}${this._uid}`
            this.chaiPayMerchantDetails.billing_details_billing_name = linkDetails.billing_details.billing_name
            this.chaiPayMerchantDetails.billing_details_billing_email = linkDetails.billing_details.billing_email
            this.chaiPayMerchantDetails.billing_details_billing_phone = linkDetails.billing_details.billing_phone
            this.phoneNumber = linkDetails.billing_details.billing_phone
            this.chaiPayMerchantDetails.description = linkDetails.description
            this.chaiPayMerchantDetails.email = linkDetails.notify_by_email
            this.chaiPayMerchantDetails.sms = linkDetails.notify_by_phone
            this.add_fields = !!linkDetails.notes
            if(linkDetails.notes) {
                this.add_fields_data = linkDetails.notes
            }
            this.paymentMethods = linkDetails.chosen_payment_methods;
            this.selectedPaymentMethods = [];
            for(let key in this.paymentMethods) {
                this.paymentMethods[key].map(element => {
                    if(element.is_enabled) {
                        this.selectedPaymentMethods.push({
                            payment_channel: element.payment_channel_key,
                            payment_method: element.payment_method_key
                        });
                    }
                });
            }
        }
    },
    watch: {
        phoneNumberVal: function (val) {
            this.chaiPayMerchantDetails.billing_details_billing_phone = (val && val.countryCallingCode && val.phoneNumber) ? `+${val.countryCallingCode}${val.phoneNumber.replace(/ /g,'')}` : ""
        },
        'chaiPayMerchantDetails.billing_details_billing_phone'(value) {
            if(value) {
                this.chaiPayMerchantDetails.sms = true;
                if(this.chaiPayMerchantDetails.send_link !== false) {
                    this.chaiPayMerchantDetails.send_link = true;
                }
            } else {
                if(!this.chaiPayMerchantDetails.billing_details_billing_email) {
                    this.chaiPayMerchantDetails.send_link = null;
                }
                this.chaiPayMerchantDetails.sms = false;
            }
        },
        'chaiPayMerchantDetails.billing_details_billing_email'(value) {
            if(value) {
                this.chaiPayMerchantDetails.email = true;
                if(this.chaiPayMerchantDetails.send_link !== false) {
                    this.chaiPayMerchantDetails.send_link = true;
                }
            } else {
                if(!this.chaiPayMerchantDetails.billing_details_billing_phone) {
                    this.chaiPayMerchantDetails.send_link = null;
                }
                this.chaiPayMerchantDetails.email = false;
            }
        },
        'chaiPayMerchantDetails.send_link'(value) {
            this.chaiPayMerchantDetails.scheduled_date = "";
            this.setExpiryDate(new Date);
            if(value === "") {
                this.chaiPayMerchantDetails.email = false;
                this.chaiPayMerchantDetails.sms = false;
            }
        },
        'chaiPayMerchantDetails.scheduled_date'(value) {
            if(value) {
                this.chaiPayMerchantDetails.send_link = false;
            }
        },
        'chaiPayMerchantDetails.expiry_date'() {
            this.setOptions();
        },
        'chaiPayMerchantDetails.reminder_time_unit'(value) {
            this.setOptions(value);
        },
        'send_reminder'(value) {
            if(!value) {
                this.chaiPayMerchantDetails.reminder_time = "";
                this.chaiPayMerchantDetails.reminder_time_unit = "";
            } else {
                this.chaiPayMerchantDetails.reminder_time_unit = this.unitOptions[0] ? this.unitOptions[0].value : "";
                this.chaiPayMerchantDetails.reminder_time = this.timeOptions[0] || "";
            }
        },
    },
    computed: {
        convertCountryToCurrency() {
            return this.$store.getters["auth/getConvertCountry"];
        },
        paymentLinkDetailStore() {
            return this.$store.state.paymentlinks.pageDetails;
        }
    },
    methods: {
        closePaymentLinkModal() {
            document.querySelector('.mdi-close').click();
        },
        resetForm() {
            this.$v.chaiPayMerchantDetails.$reset();
            // this.$v.chaiPayMerchantDetails.val()
            this.orderLink = "";
            this.payment_link_ref="";
        },
        resetCreatePaymentLinkForm() {
            this.chaiPayMerchantDetails = {
                amount: "",
                currency: this.$route.query.lastCurrency ? this.$route.query.lastCurrency : this.convertCountryToCurrency,
                country:  this.$route.query.lastCountry ?  this.$route.query.lastCountry : this.$store.state.auth.currentUser.country,
                // expiry_hours: "48",
                expiry_date: "",
                merchant_order_id: `${(this._uid + this.$uuid.v1())
                .replace(/-/g, "")
                .substring(1, 13)
                .toUpperCase()}${this._uid}`,
                billing_details_billing_name: "",
                billing_details_billing_email: "",
                billing_details_billing_phone: "",
                description: "",
                ref_link:"",
                checked_ref_link:false,
                send_link:false,
                email:false,
                sms:false,
                success_url:"",
                failure_url:"",
                // add_reminder:false,
                // set_period:'1',
                // set_freqeuncy:'Daily',
                // set_time:"",
                scheduled_date: ""
            };
            this.phoneNumberVal = null;
            this.phoneNumber = "";
            this.add_fields = false;
            this.add_fields_data = [{"key": "", "value": ""}];
            this.add_fields_error = false;
        },
        submitPaymentLinks(event) {
            this.isProcessing = true;
            if(!!this.chaiPayMerchantDetails.billing_details_billing_phone && !this.phoneNumberVal.isValid) {
                this.$notify({
                    type: "error",
                    text: "Please enter valid phone number",
                    closeOnClick: true,
                });
                this.isProcessing = false;
                return false;
            }
            if(this.add_fields) {
                var isField = [];
                var fieldToSend = [];
                this.add_fields_data.filter(obj => {
                    if(obj.key && obj.value) {
                        fieldToSend.push(obj)
                    }else{
                        isField.push(obj) 
                    }
                });
                if(isField.length > 0) {
                    this.add_fields_error = true;
                    this.isProcessing = false;
                    return false;
                }
            }    
            this.typesubmit = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.isProcessing = false;
                return false;
            }

            const addPaymentLinksDetails = {
                chaipay_key: this.$store.state.auth.currentUser.iamportKey,
                merchant_details: {
                name: this.$store.state.merchant.entityDetails.brand_name,
                // logo: this.$store.state.merchant.entityDetails.merchant_logo | '',
                logo: "",
                back_url: CHAIPAY_APP_URL, // this.$store.state.merchant.entityDetails.name.back_url,
                promo_code: "NA", //event.target.promo_code.value || "",
                promo_discount: 0.0, //event.target.promo_discount.value,
                shipping_charges: 0.0, //event.target.shipping_charges.value,
                },
                source: "default",
                description: event.target.description.value,
                signature_hash: "signature_hash",
                amount: Number.parseFloat(event.target.amount.value, 2),
                currency: event.target.currency.value,
                country_code: this.chaiPayMerchantDetails.country,
                custom_link_ref:this.chaiPayMerchantDetails.ref_link,
                merchant_order_id: event.target.merchant_order_id.value,
                show_shipping_details:false,
                billing_details: {
                    billing_name: event.target.billing_details_billing_name.value,
                    billing_email: event.target.billing_details_billing_email.value,
                    billing_phone:
                        this.chaiPayMerchantDetails.billing_details_billing_phone,
                    billing_address: {
                        city: "City",
                        country_code: this.chaiPayMerchantDetails.country,
                        locale: this.chaiPayMerchantDetails.country,
                        line_1: "address",
                        line_2: "address_2",
                        postal_code: "400202",
                        state: "Mah",
                    },
                },
                shipping_details: {
                    shipping_name: event.target.billing_details_billing_name.value,
                    shipping_email: event.target.billing_details_billing_email.value,
                    shipping_phone:
                        this.chaiPayMerchantDetails.billing_details_billing_phone,
                    shipping_address: {
                        city: "abc",
                        country_code: this.chaiPayMerchantDetails.country,
                        locale: this.chaiPayMerchantDetails.country,
                        line_1: "address_1",
                        line_2: "address_2",
                        postal_code: "400202",
                        state: "Mah",
                    },
                },
                is_checkout_embed:false,
                success_url: event.target.success_url.value ? event.target.success_url.value : `${process.env.VUE_APP_CHECKOUT_BASE_URL}/success.html`,
                failure_url: event.target.failure_url.value ? event.target.failure_url.value : `${process.env.VUE_APP_CHECKOUT_BASE_URL}/failure.html`,
                pending_url: `${process.env.VUE_APP_CHECKOUT_BASE_URL}/pending.html`,
                // expiry_hours: +event.target.expiry_hours.value,
                expiry_date: this.chaiPayMerchantDetails.expiry_date,
                customer_details: {
                  name: event.target.billing_details_billing_name.value,
                  email_address: event.target.billing_details_billing_email.value,
                  phone_number: this.chaiPayMerchantDetails.billing_details_billing_phone
                },
                notify_by_email: this.chaiPayMerchantDetails.email,
                notify_by_phone: this.chaiPayMerchantDetails.sms,
                notes: fieldToSend,
                reminder_time: Number.parseInt(this.chaiPayMerchantDetails.reminder_time),
                reminder_time_unit: this.chaiPayMerchantDetails.reminder_time_unit,
                send_immediately: this.chaiPayMerchantDetails.send_link !== "" ? this.chaiPayMerchantDetails.send_link : false,
                scheduled_date: this.chaiPayMerchantDetails.scheduled_date,
                chosen_payment_methods: this.selectedPaymentMethods
            };

            // Dispatch action to add payment links
            this.$store.dispatch(`paymentlinks/${POST_PAYMENT_LINKS}`, addPaymentLinksDetails)
            .then((response) => {
                this.orderLink = response.payment_link;
                this.payment_link_ref = response.payment_link_ref
                // this.$notify({
                //     type: "success",
                //     text: "Payment Link generated successfully.",
                //     closeOnClick: true,
                // });
                this.$refs['modal-2'].show()
                setTimeout(() => {
                    this.resetCreatePaymentLinkForm();
                }, 500);
            })
            .catch((error) => {
            // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: this.$store.state.notification.message,
                    closeOnClick: true,
                });
            })
            .finally(() => this.isProcessing = false);
        },
        changefields() {
            this.add_fields_error = false
            if(!this.add_fields) {
                this.add_fields_data = [{"key": "", "value": ""}]
            }
        },
        reset_add_field_error() {
            this.add_fields_error = false
        },
        add_field_button() {
            this.add_fields_error = false
            var isField = [];
            isField = this.add_fields_data.filter(obj => {
                if(obj.key && obj.value) {
                // do nothing
                }else{
                return obj
                }
            });
            if(isField.length > 0) {
                this.add_fields_error = true
            }else{
                this.add_fields_data.push({"key": "", "value": ""});
            }
        },
        setExpiryDate(date, type) {
            let currentDate = new Date(date);

            this.chaiPayMerchantDetails.expiry_date = new Date(currentDate.getTime() + 2 * 24 * 3600 * 1000)

            if (type === 'minute') {
                this.datepickerOpen = false;
            }
        },
        disabledBeforeTodayAndAfterAWeek(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
        },
        disabledBeforeTodayAndAfterOneMonth(date) {
            let currentDate;
            if(this.chaiPayMerchantDetails.scheduled_date) {
                currentDate = new Date(this.chaiPayMerchantDetails.scheduled_date);
            } else {
                currentDate = new Date();
            }
            currentDate.setHours(0, 0, 0, 0);
            return date < currentDate || date > new Date(currentDate.setMonth(currentDate.getMonth()+1));
        },
        disabledBeforeOneHour(date) {
            let currentDate;
            if(this.chaiPayMerchantDetails.scheduled_date) {
                currentDate = new Date(this.chaiPayMerchantDetails.scheduled_date);
            } else {
                currentDate = new Date();
            }

            return date < new Date(currentDate.getTime() + 1 * 60 * 60 * 1000);
        },
        notBeforeToday(date) {
            return date < new Date(new Date().getTime() + 1 * 60 * 60 * 1000);
        },
        diffHours(dt2, dt1) {
            var diff =(dt2.getTime() - dt1.getTime()) / 1000;
            diff /= (60 * 60);
            return Math.abs(Math.round(diff));
        },
        setOptions(type = null) {
            let firstDate;
            let lastDate = this.chaiPayMerchantDetails.expiry_date;
            if(this.chaiPayMerchantDetails.scheduled_date) {
                firstDate = this.chaiPayMerchantDetails.scheduled_date;
            } else {
                firstDate = new Date();
            }

            let diffInhours = this.diffHours(firstDate, lastDate);
            if(diffInhours > 30) {
                this.unitOptions = [{ value: "hours", text: "Hours before due" }, { value: "days", text: "Days before due" }];
                if(type == 'hours') {
                    this.timeOptions =  Array(23).fill().map((x,i) => i + 1);
                } else {
                    let daysCount = Math.floor(diffInhours / 24);
                    this.timeOptions =  Array(daysCount).fill().map((x,i) => i + 1);
                }

            } else {
                this.unitOptions = [{ value: "hours", text: "Hours before due" }];
                this.timeOptions =  Array(diffInhours - 1).fill().map((x,i) => i + 1);
            }
        },
        checkRefLinkKeyup() {
            this.chaiPayMerchantDetails.checked_ref_link = false
        },
        checkRefLink() {
            this.$store.dispatch(`paymentlinks/${CHECK_REF_LINK_PAYMENT_LINKS}`, this.chaiPayMerchantDetails.ref_link)
            .then(() => {
                this.chaiPayMerchantDetails.checked_ref_link = true
            })
            .catch((error) => {
            // error
                this.chaiPayMerchantDetails.checked_ref_link = false
                console.log("error", error);
                const errorObject = JSON.parse(error.message)
                this.$notify({
                    type: "error",
                    text: errorObject.error,
                    closeOnClick: true,
                });
            });
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.chaiPayMerchantDetails[name];
            return $dirty ? !$error : null;
        },
        onCopySuccess() {
            this.$notify({
                type: "success",
                text: "Payment Link copied to clipboard.",
                closeOnClick: true,
            });
        },
        handleDatePicker(value, type) {
            if (type === 'minute') {
                this.expiryDatepickerOpen = false;
            }
        },
        getRegionName(countryCode) {
            let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
            return regionNames.of(countryCode);
        },
        storePaymentMethods(value) {
            this.selectedPaymentMethods = value;
        },
        hideModal() {
            this.$refs['modal-2'].hide()
        },
        countryChanged(value) {
            this.chaiPayMerchantDetails.country = value;
        }
    }
};
</script>

<template>
    <Layout>
    <div class="row">
    <div class="col-lg-12">
    <div>
         <!-- <form
            v-show="!orderLink"
            class="row"
            @submit.prevent="submitPaymentLinks"
        > -->
        <form
            class="row"
            @submit.prevent="submitPaymentLinks"
        >
            <div class="col-lg-8 col-8">
                <div class="top-section">
                    <div class="top-section-header">
                        <div style="font-size: 16px;color: #000000;font-weight: 350;">Create Payment Link</div>
                        <div style="font-size: 12px;color: #00000080;margin-top: 10px;font-weight: 325;">Fill out payment link details to collect payments from your customers</div>
                    </div>
                    <div class="top-section-body" style="padding: 20px 20px 20px 10px;">
                        <div class="col-lg-8 col-8">
                            <div class="form-group">
                                <label
                                >{{ $t("views.payment_links.country")
                                }}<span class="text text-danger">*</span></label
                                >

                                <Country :country="chaiPayMerchantDetails.country" @country-changed="countryChanged" />
                                <div
                                    v-if="$v.chaiPayMerchantDetails.country.$error"
                                    class="text-danger font-size-12"
                                >
                                    {{
                                        "This value is required"
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-8 form-group">
                                <label>
                                <!-- <i class="mdi mdi-cash mr-1"></i> -->
                                {{ $t("views.payment_links.amount")
                                }}<span class="text text-danger">*</span></label
                                >
                                <b-input-group>
                                    <template #prepend>
                                         <b-form-select
                                            name="currency"
                                            v-model="chaiPayMerchantDetails.currency"
                                            class="custom-dropdown-select"
                                            style="padding-left: 16px;padding-right: 22px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                                            @change="() => selectedPaymentMethods = []"
                                        >
                                            <b-form-select-option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</b-form-select-option>
                                        </b-form-select>
                                    </template>

                                <b-form-input
                                        v-model="$v.chaiPayMerchantDetails.amount.$model"
                                        type="number"
                                        step="any"
                                        class="form-control"
                                        placeholder="Enter payment amount"
                                        name="amount"
                                        @mousewheel.prevent
                                        :state="validateState('amount')"
                                        :class="{
                                            'is-invalid':
                                            typesubmit && $v.chaiPayMerchantDetails.amount.$error,
                                        }"
                                    />
                                    <div
                                        v-if="$v.chaiPayMerchantDetails.amount.$error"
                                        class="invalid-feedback"
                                    >
                                    <span v-if="!$v.chaiPayMerchantDetails.amount.required">{{
                                        "This value is required"
                                    }}</span>
                                    </div>
                                </b-input-group>


                            <!-- <div class="form-group">
 
                            </div> -->
                        </div>
                        <!-- <div class="col-lg-8 col-8">
                            <div class="form-group position-relative"> -->
                                <!-- <label
                                >{{ $t("views.payment_links.currency")
                                }}<span class="text text-danger">*</span></label
                                > -->
                                <!-- <img
                                    v-if="chaiPayMerchantDetails.currency == 'VND'"
                                    class="flag_images"
                                    src="@/assets/images/flags/vietnam1.png"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'THB'"
                                    class="flag_images"
                                    src="@/assets/images/flags/thailand.jpeg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'SGD'"
                                    class="flag_images"
                                    src="@/assets/images/flags/singapore.png"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'IDR'"
                                    class="flag_images"
                                    src="@/assets/images/flags/indonesia.png"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'PHP'"
                                    class="flag_images"
                                    src="@/assets/images/flags/philippines.png"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'MYR'"
                                    class="flag_images"
                                    src="@/assets/images/flags/malaysia.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'TWD'"
                                    class="flag_images"
                                    src="@/assets/images/flags/taiwan.png"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'HKD'"
                                    class="flag_images"
                                    src="@/assets/images/flags/hong_kong.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'KHR'"
                                    class="flag_images"
                                    src="@/assets/images/flags/cambodia.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'WST'"
                                    class="flag_images"
                                    src="@/assets/images/flags/samoa.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'USD'"
                                    class="flag_images"
                                    src="@/assets/images/flags/united_states.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'INR'"
                                    class="flag_images"
                                    src="@/assets/images/flags/india.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'AUD'"
                                    class="flag_images"
                                    src="@/assets/images/flags/australia.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'KRW'"
                                    class="flag_images"
                                    src="@/assets/images/flags/korea.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'JPY'"
                                    class="flag_images"
                                    src="@/assets/images/flags/japan.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'EUR'"
                                    class="flag_images"
                                    src="@/assets/images/flags/europe.svg"
                                    alt=""
                                />
                                <img
                                    v-if="chaiPayMerchantDetails.currency == 'AED'"
                                    class="flag_images"
                                    src="@/assets/images/flags/uae.svg"
                                    alt=""
                                />
                                <div
                                    v-if="$v.chaiPayMerchantDetails.currency.$error"
                                    class="text-danger font-size-12"
                                >
                                    {{
                                        "This value is required"
                                    }}
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label
                                >{{ $t("views.payment_links.link_reference") }}<span class="text text-danger">*</span></label
                                >
                                <input
                                    v-model="chaiPayMerchantDetails.merchant_order_id"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Merchant Link Reference"
                                    name="merchant_order_id"
                                    :class="{
                                        'is-invalid':
                                        typesubmit &&
                                        $v.chaiPayMerchantDetails.merchant_order_id.$error,
                                    }"
                                />
                                <div
                                v-if="
                                    typesubmit &&
                                    $v.chaiPayMerchantDetails.merchant_order_id.$error
                                "
                                class="invalid-feedback"
                                >
                                <span
                                    v-if="!$v.chaiPayMerchantDetails.merchant_order_id.required"
                                    >{{ "This value is required." }}</span
                                >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>
                                <!-- <i class="mdi mdi-tooltip-edit mr-2"></i> -->
                                {{ $t("views.payment_links.description") }}</label
                                >
                                <div>
                                    <textarea
                                        v-model.trim="chaiPayMerchantDetails.description"
                                        type="text"
                                        class="form-control"
                                        placeholder="Let your customer know what is this payment link for"
                                        name="description"
                                        :maxlength="max"
                                    />
                                    <div style="margin-top: 5px;">
                                        <span style="color: #FC6B2D;font-size: 14px;">Note :</span><span style="opacity: 0.50;color: black;font-size: 12px;padding-left: 10px;">Maximum 200 characters are allowed.</span>
                                    </div>
                                    <!-- <div
                                        class="input-group-addon"
                                        v-text="max - chaiPayMerchantDetails.description.length"
                                    ></div> -->
                                </div>
                                <!-- <div
                                        v-if="typesubmit && $v.chaiPayMerchantDetails.billing_details_billing_email.$error"
                                        class="invalid-feedback"
                                        >
                                        <span v-if="!$v.chaiPayMerchantDetails.billing_details_billing_email.email">{{ 'This is not valid email.' }}</span>
                                        </div> -->
                            </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>{{ $t("views.payment_links.customer_url") }} <span style="color: rgba(0, 0, 0, 0.50);font-size: 15px;">(Optional)</span></label>
                                <div class="mt-2 d-flex flex-wrap">
                                    <p style="height: 44px;line-height: 44px;background: #f4f4f2;margin-bottom: 0px;border-bottom-left-radius: 12px;border-top-left-radius: 12px;color: #000;padding-left: 16px;font-size: 14px;padding-right: 15px;border: 1px #DCDAD5 solid;border-right: none;">{{ checkout_base_url }}/?ref=</p>
                                    <b-input type="text" name="merchant_ref_link" v-model="chaiPayMerchantDetails.ref_link" class="flex-fill" id="merchant_ref_link" :class="chaiPayMerchantDetails.checked_ref_link == true ? 'border-success' : (chaiPayMerchantDetails.checked_ref_link == false ? 'border-danger' : '')" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px; width: unset;"></b-input>
                                    <b-btn @click="checkRefLink" class="p-0 border-0 my-auto ml-2" variant="white">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 44 44" fill="none">
                                            <g opacity="0.3">
                                            <circle cx="22" cy="22" r="22" fill="#DCDAD5"/>
                                            <circle cx="22" cy="22" r="21.5" stroke="black" stroke-opacity="0.5"/>
                                            </g>
                                            <path d="M23.8225 17.4473L28.375 21.9998L23.8225 26.5523M15.625 21.9998H28.2475" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </b-btn>
                              </div>
                               <div style="margin-top: 5px;">
                                  <span style="opacity: 0.50;color: black;font-size: 12px;padding-left: 16px;">Set a custom URL slug for easy identification of your payment link</span>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="middle-section" style="margin-top: 20px;">
                    <div v-b-toggle.collapse-customer-information class="middle-section-header d-flex justify-content-between custom-collapse">
                        <div>
                          <div style="font-size: 16px;color: #000000;font-weight: 350;">Customer Information (<span style="color: #FC6B2D;font-size: 14px;font-weight: normal;">Optional</span>)</div>
                          <div style="font-size: 12px;color: #00000080;margin-top: 10px;font-weight: 325;">Provide your customer's details and set up communication preferences</div>
                        </div>
                        <img v-show="showCollapse.customerInfo" src="@/assets/images/drop_up.svg" alt="down"/>
                        <img v-show="!showCollapse.customerInfo" src="@/assets/images/drop_down.svg" alt="down"/>
                    </div>
                    <b-collapse v-model="showCollapse.customerInfo" id="collapse-customer-information">
                      <div class="middle-section-body" style="padding: 20px 20px 20px 10px;">
                          <div class="col-lg-12">
                              <div class="form-group">
                                  <label>
                                  <!-- <i class="mdi mdi-account mr-2"></i> -->
                                  {{ $t("views.payment_links.customer_name") }}</label
                                  >
                                  <input
                                      v-model.trim="$v.chaiPayMerchantDetails.billing_details_billing_name.$model"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter customer name"
                                      name="billing_details_billing_name"
                                      :class="{
                                          'is-invalid':
                                          typesubmit && $v.chaiPayMerchantDetails.billing_details_billing_name.$error,
                                      }"
                                  />
                                  <div
                                      v-if="$v.chaiPayMerchantDetails.billing_details_billing_name.$error"
                                      class="invalid-feedback"
                                  >
                                  {{
                                      "Max 30 characters are allowed"
                                  }}
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-12">
                              <div class="form-group">
                                  <label>
                                  <!-- <i class="mdi mdi-email mr-2"></i> -->
                                  {{ $t("views.payment_links.customer_email") }}</label
                                  >
                                  <b-form-input
                                      v-model="
                                          $v.chaiPayMerchantDetails.billing_details_billing_email
                                          .$model
                                      "
                                      type="email"
                                      class="form-control billing_details_billing_email"
                                      placeholder="Enter customer's email"
                                      name="billing_details_billing_email"
                                      :state="validateState('billing_details_billing_email')"
                                  />
                                  <div
                                  v-if="
                                      $v.chaiPayMerchantDetails.billing_details_billing_email
                                      .$error
                                  "
                                  class="invalid-feedback"
                                  >
                                  <span
                                      v-if="
                                      !$v.chaiPayMerchantDetails.billing_details_billing_email
                                          .email
                                      "
                                      >{{ "This is not valid email." }}</span
                                  >
                                  </div>
                                  <b-form-checkbox
                                      style="margin-top: 15px; margin-left: 25px;"
                                      v-model="chaiPayMerchantDetails.email"
                                      class="email-sms-label"
                                      :value="true"
                                      :unchecked-value="false"
                                      :disabled="!chaiPayMerchantDetails.billing_details_billing_email"
                                  >
                                      Send Payment Link via Email?
                                  </b-form-checkbox>
                              </div>
                          </div>
                          <div class="col-lg-12">
                              <div class="form-group">
                                  <label>
                                  <!-- <i class="mdi mdi-phone mr-2"></i> -->
                                  {{ $t("views.payment_links.customer_phone") }}</label
                                  >
                                  <VuePhoneNumberInput
                                      v-model="phoneNumber"
                                      id="VuePhoneNumber"
                                      @update="phoneNumberVal = $event"
                                      :default-country-code="chaiPayMerchantDetails.country"
                                      :preferred-countries="[
                                          'KH',
                                          'HK',
                                          'IN',
                                          'ID',
                                          'MY',
                                          'PH',
                                          'WS',
                                          'SG',
                                          'KR',
                                          'TH',
                                          'VN',
                                          'TW',
                                          'US',
                                          'KE',
                                          'ZA',
                                          'NG'
                                      ]"
                                  />
                                  <!-- <div
                                          v-if="typesubmit && $v.chaiPayMerchantDetails.billing_details_billing_phone.$error"
                                          class="invalid-feedback"
                                          >
                                          <span v-if="!$v.chaiPayMerchantDetails.billing_details_billing_phone.required">{{ 'This value is required.' }}</span>
                                          </div> -->
                                  <b-form-checkbox
                                      style="margin-top: 15px; margin-left: 25px; font-size:14px; font-weight:500; color:#000"
                                      class="email-sms-label"
                                      v-model="chaiPayMerchantDetails.sms"
                                      :value="true"
                                      :unchecked-value="false"
                                      :disabled="!this.chaiPayMerchantDetails.billing_details_billing_phone"
                                  >
                                      Send Payment Link via SMS?
                                  </b-form-checkbox>
                              </div>
                          </div>
                          <div class="col-lg-12 form-group">
                              <div class="form-group">
                                  <label class="">{{ $t("views.payment_links.send_link") }}</label>
                                  <div
                                      class="py-1 d-flex justify-content-between"
                                      id="single-merchant-cont"
                                      style="font-size: 14px; color: #505d69; background: #fff"
                                  >
                                      <b-form-checkbox-group
                                          class="my-auto"
                                          v-model="chaiPayMerchantDetails.send_link"
                                          :disabled="!chaiPayMerchantDetails.billing_details_billing_phone && !chaiPayMerchantDetails.billing_details_billing_email"
                                      >
                                          <b-form-checkbox :value="true" unchecked-value="">{{ $t("views.payment_links.send_immediately") }}</b-form-checkbox>
                                          <b-form-checkbox :value="false" unchecked-value="">{{ $t("views.payment_links.schedule") }}</b-form-checkbox>
                                      </b-form-checkbox-group>
                                      <date-picker
                                          type="datetime"
                                          placeholder="Schedule Send"
                                          format="DD MMM YYYY HH:mm"
                                          v-model="chaiPayMerchantDetails.scheduled_date"
                                          name="startdate"
                                          class="w-50"
                                          prefix-class="xmx"
                                          :open.sync="datepickerOpen"
                                          @change="setExpiryDate"
                                          v-show="chaiPayMerchantDetails.send_link === false ? true : false"
                                          :disabled-date="disabledBeforeTodayAndAfterAWeek"
                                          :disabled-time="notBeforeToday"
                                      ></date-picker>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <b-form-checkbox class="mb-2" v-model="send_reminder" :value="true">{{ $t("views.payment_links.send_reminder") }}</b-form-checkbox>
                                  <div class="d-flex" v-if="send_reminder">
                                      <b-form-select
                                          v-model="chaiPayMerchantDetails.reminder_time"
                                          :options="timeOptions"
                                          class="w-25 mr-3"
                                      >
                                          <template #first>
                                              <b-form-select-option value=""
                                                  >-- Select --</b-form-select-option
                                              >
                                          </template>
                                      </b-form-select>
                                      <b-form-select
                                          v-model="chaiPayMerchantDetails.reminder_time_unit"
                                          :options="unitOptions"
                                          class="w-75"
                                      >
                                          <template #first>
                                              <b-form-select-option value=""
                                                  >-- Please select an option --</b-form-select-option
                                              >
                                          </template>
                                      </b-form-select>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-12">
                              <div class="form-group">
                                  <div class="d-flex">
                                      <b-form-checkbox
                                          v-model="add_fields"
                                          @input="changefields"
                                          value="true"
                                          class="d-flex"
                                      >
                                          {{ $t("views.payment_links.add_fields") }}
                                      </b-form-checkbox>
                                      <img
                                          id="addFieldTooltip"
                                          src="@/assets/images/tooltip_icon.svg"
                                          style="position: relative; bottom: 1px"
                                          class="ml-2 cursor-pointer"
                                          alt="filter"
                                          title="Here you can add additional information related to your customer or payment"
                                          v-b-tooltip.hover
                                      />
                                  </div>
                                  <div
                                      v-if="add_fields"
                                      class="form-group"
                                      style="margin-top: 0.5rem"
                                  >
                                  <div
                                      class="row"
                                      v-for="(data, index) in add_fields_data"
                                      :key="index"
                                  >
                                      <div class="col-lg-6 col-6">
                                      <div class="form-group">
                                          <input
                                          v-model="data.key"
                                          type="text"
                                          class="form-control"
                                          placeholder="Title"
                                          @keyup="reset_add_field_error"
                                          name="title"
                                          />
                                      </div>
                                      </div>
                                      <div class="col-lg-6 col-6">
                                      <div class="form-group">
                                          <input
                                          v-model="data.value"
                                          type="text"
                                          class="form-control"
                                          placeholder="Description"
                                          @keyup="reset_add_field_error"
                                          name="notes-description"
                                          />
                                      </div>
                                      </div>
                                  </div>
                                  <div v-if="add_fields_error" class="error">
                                      <span>Title and Description can not be empty</span>
                                  </div>
                                  <div class="row">
                                      <div class="col-lg-6 col-6">
                                          <div
                                              class="bg-white btn btn-sm"
                                              @click="add_field_button"
                                              style="
                                              color: #FC6B2D;,font-size: 14px;font-weight: 400;padding: 0px;line-height: normal;"
                                          >
                                             + {{ $t("views.payment_links.add_fields") }}
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </b-collapse>
                </div>
                <div class="bottom-section" style="margin-top: 20px;margin-bottom: 30px;">
                    <div v-b-toggle.collapse-additional-information class="bottom-section-header d-flex justify-content-between custom-collapse">
                        <div>
                          <div style="font-size: 16px;color: #000000;font-weight: 350;">Additional Information (<span style="color: #FC6B2D;font-size: 14px;font-weight: normal;">Optional</span>)</div>
                          <div style="font-size: 12px;color: #00000080;margin-top: 10px;font-weight: 325;">Set payment due date, payment methods and more</div>
                        </div>
                        <img v-show="showCollapse.additionalInfo" src="@/assets/images/drop_up.svg" alt="down"/>
                        <img v-show="!showCollapse.additionalInfo" src="@/assets/images/drop_down.svg" alt="down"/>
                    </div>
                    <b-collapse v-model="showCollapse.additionalInfo" id="collapse-additional-information">
                      <div class="bottom-section-body" style="padding: 20px 20px 20px 10px;">
                          <div class="col-lg-12">
                              <div class="form-group w-50 mt-2">
                                  <label
                                  >{{ $t("views.payment_links.expiry_date")
                                  }}<span class="text text-danger">*</span></label
                                  >
                                  <date-picker
                                      type="datetime"
                                      placeholder="Expiry Date"
                                      format="DD MMM YYYY HH:mm"
                                      v-model="chaiPayMerchantDetails.expiry_date"
                                      name="startdate"
                                      class=""
                                      prefix-class="xmx"
                                      :disabled-date="disabledBeforeTodayAndAfterOneMonth"
                                      :disabled-time="disabledBeforeOneHour"
                                      :open.sync="expiryDatepickerOpen"
                                      @change="handleDatePicker"
                                  ></date-picker>
                                  <div
                                  v-if="
                                      typesubmit && $v.chaiPayMerchantDetails.expiry_date.$error
                                  "
                                  class="invalid-feedback"
                                  style="display: block;"
                                  >
                                  <span
                                      v-if="!$v.chaiPayMerchantDetails.expiry_date.required"
                                      >{{ "This value is required." }}</span
                                  >
                                  </div>
                              </div>
                              <div class="form-group">
                                <payment-method-modal @payment-methods="storePaymentMethods" :currency="chaiPayMerchantDetails.currency" />
                              </div>
                              <div style=" border: 1px rgba(220, 218, 213, 0.50) solid; margin-bottom:25px;"></div>
                              <div class="form-group">
                                <label style="margin-bottom: 5px;">Payment Redirect URLs <span style="color: rgba(0, 0, 0, 0.50);font-size: 15px;">(Optional)</span></label>
                                <div style="opacity: 0.50;color: black;font-size: 12px;">Set custom redirect URLs for successful or failed transactions</div>
                            </div>
                            <div class="form-group">
                                <label>{{ "Successful Payment Redirect URL"}}</label>
                                <input
                                    v-model="chaiPayMerchantDetails.success_url" 
                                    type="text"
                                    class="form-control"
                                    placeholder="https://example.com/success.html"
                                    name="success_url"
                                />
                            </div>
                             <div class="form-group">
                                <label>{{ "Failed Payment Redirect URL"}}</label>
                                <input
                                    v-model="chaiPayMerchantDetails.failure_url" 
                                    type="text"
                                    class="form-control"
                                    placeholder="https://example.com/failure.html"
                                    name="failure_url"
                                />
                            </div>
                          </div>
                      </div>
                    </b-collapse>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <div>
                    <button
                        style="width: 100%;background: #000000;font-weight: 400;font-size: 16px;padding: 15px;"
                        type="submit"
                        :disabled="isProcessing"
                        class="btn btn-primary"
                    >
                    {{ $t("views.payment_links.create_pay_link") }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g id="Iconsax/Linear/arrowright">
                            <path id="Vector" d="M14.43 5.93018L20.5 12.0002L14.43 18.0702M3.5 12.0002H20.33" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                        </svg>
                    </button>
                    <!-- <button
                        style="width: 100%; margin-top: 10px"
                        @click="closePaymentLinkModal"
                        id="close-payment-link-form"
                        class="btn btn-light mr-2"
                    >
                    {{ $t("button.close") }}
                    </button> -->
                </div>
                <div style="padding: 25px;box-shadow: rgba(220, 218, 213, 0.3) 0px 4px 12px 1px;border-radius: 15px;margin-top: 15px;">
                    <p style="font-size: 20px;color: #FC6B2D; margin-bottom: 0px;}">Payment Summary</p>
                    <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; margin-top: 40px;">
                        <div>Subtotal</div>
                        <div style="margin-left: auto;order: 2;">{{chaiPayMerchantDetails.currency}} {{chaiPayMerchantDetails.amount}}</div>
                    </div>
                    <div class="summary-details d-flex" style="color: #000000;font-size: 14px; margin-top: 10px;">
                        <div>Total Amount Due</div>
                        <div style="margin-left: auto;order: 2;">{{chaiPayMerchantDetails.currency}} {{chaiPayMerchantDetails.amount}}</div>
                    </div>
                    <div style="border-bottom: 1px #DCDAD5 solid;margin-top: 20px;margin-bottom: 20px;"></div>
                    <div class="summary-details d-flex" style="color: #00000080;font-size: 12px;">
                        <div>Payment Expiry Date</div>
                        <div style="margin-left: auto;order: 2;">{{ chaiPayMerchantDetails.expiry_date | date }}</div>
                    </div>
                    <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; margin-top: 10px;">
                        <div>Payment Expiry Time</div>
                        <div style="margin-left: auto;order: 2;">{{ chaiPayMerchantDetails.expiry_date | time }}</div>
                    </div>
                    <div style="border-bottom: 1px #DCDAD5 solid;margin-top: 20px;margin-bottom: 20px;"></div>
                    <p style="font-size: 14px;color: #000000; margin-bottom: 10px;}">Customer Details</p>
                    <div class="summary-details d-flex" style="color: #00000080;font-size: 12px;">
                        <div>Name</div>
                        <div style="margin-left: auto;order: 2;">{{ chaiPayMerchantDetails.billing_details_billing_name }}</div>
                    </div>
                    <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; margin-top: 10px;">
                        <div>Email</div>
                        <div style="margin-left: auto;order: 2;">{{chaiPayMerchantDetails.billing_details_billing_email}}</div>
                    </div>
                </div>
            </div>
        </form>
        <div v-show="orderLink" class="col-lg-12 my-1 text-center">
        <b-modal
                id="modal-2"
                ref="modal-2"
                title=""
                hide-footer
                no-close-on-backdrop
                header-class="pmt-modal-header-custom"
                body-class="pmt-modal-body-custom"
                footer-class="pmt-modal-footer-custom"
            >
                <div class="text-center">
                    <div class="text-black" style="font-size: 35px; margin-bottom: 5px;">Congratulations!</div>
                    <div style="color: rgba(0, 0, 0, 0.5);font-size: 20px;line-height: 38px;margin-top: 18px;">Your Payment Link has been created</div>
                    <div style="color: rgba(0, 0, 0, 0.50);font-size: 20px;">successfully.</div>
                    <div style="width: 200px;margin: auto;background: rgba(252, 107, 45, 0.1);border-radius: 50%;padding: 50px 0px;margin-top: 25px;"><img width="100" height="100" src="@/assets/images/success_new.png" alt /></div>
                    <div class="" style="border: 1px solid rgb(220, 218, 213);border-radius: 8px;color: rgba(0, 0, 0, 0.7);margin-top: 30px;padding-top: 13px;padding-bottom: 13px;padding-left: 16px;background: white;box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;">
                        <span class="text-truncate" style="font-size: 14px;width: calc(100% - 40px);display: inline-block;">{{ orderLink }}</span>
                        <span  v-clipboard:copy="orderLink"
                        v-clipboard:success="onCopySuccess"
                        style="cursor: pointer;padding-left: 10px;padding-right: 0px;width: 36px;height: 36px;display: inline-block;float: right;background: rgb(217, 217, 217);border-radius: 8px;position: relative;bottom: 10px;right: 4px;">
                          <svg style="position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g id="Iconsax/Linear/copy" opacity="0.5">
                            <path id="Vector" d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path id="Vector_2" d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                        </span>
                    </div>
                    <div style="font-size: 16px;margin-top: 15px;text-decoration: underline;color:#FC6B2D;">
                        <a target="_blank"  style="color:#FC6B2D;" :href="orderLink">Check Payment Link Preview Here 
                          <svg style="margin-left: 10px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g id="Iconsax/Linear/exportsquare">
                            <path id="Vector" d="M13 11L21.2 2.8M22 6.8V2H17.2M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                          </svg>
                        </a>
                    </div>
                    <div class="d-flex justify-content-center">
                      <div style="margin-top: 40px;margin-bottom: 40px;">
                        <b-btn style="padding: 12px 25px;" @click="$router.push({path: '/payment-links-details', query: { pageRef: payment_link_ref }})"  variant="primary" class="mr-2 bg-white text-dark">View Details</b-btn>
                        <b-btn style="padding: 12px 25px; margin-left: 25px;" variant="primary" @click="hideModal">Create New Payment Link</b-btn>
                      </div>
                    </div>
                </div>
        </b-modal>
        </div>
    </div>
    </div>
    </div>
    </Layout>
</template>

<style>
.xmx-datepicker-popup{
  z-index: 9999;
}
</style>

<style lang="css" scoped>
.checkbox-group-custom{
  position: relative;
}
.checkbox-group-custom input{
  display: none;
}
.checkbox-group-custom  label {
  cursor: pointer;
}
.checkbox-group-custom{
  display: inline-block;
}
.checkbox-group-custom  label {
  cursor: pointer;
}
.checkbox-group-custom  label:before {
  content:'';
  --webkit-appearance: none;
  background-color: #EAEAEA;
  border-radius: 4px;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group-custom  label.filled:before {
  background-color: #252B3A;
}

.checkbox-group-custom label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.cursor-pointer{
  cursor: pointer;
}

::v-deep .billing_details_billing_email.is-valid {
  border-color: #ced4da;
  background-image: none;
  box-shadow: none;
}

/* ::v-deep #VuePhoneNumber .resize-observer{
  display: none;
} */

::v-deep .flag_images{
  position: absolute;
  width: 20px;
  top: 42px;
  left: 10px;
}

::v-deep .custom-select-for-flag{
  padding-left:35px ;
}

::v-deep .input-group-addon{
   padding: 10px 10px;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    background-color: #ecf0f1;
    border: 1px solid #dce4ec;
    border-left-color: transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

::v-deep .xmx-datepicker{
  width: 100%;
}

::v-deep .custom-datepicker .xmx-input{
  height: auto !important;
  padding-top: 15px;
  min-height: unset;
}

::v-deep .custom-control {
    z-index: unset;
}

.input-group-append .btn {
    z-index: unset;
}
.top-section-header, .middle-section-header,.bottom-section-header{
  background: #dcdad54d;
  padding: 18px 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px #DCDAD5 solid;
}
.top-section, .middle-section, .bottom-section{
    box-shadow: 0px 4px 12px 1px rgba(220, 218, 213, 0.30);
    border-radius: 15px;
}
::v-deep .top-section label, ::v-deep .middle-section label, ::v-deep .bottom-section label{
    color: black;
    font-size: 14px;
    margin-bottom: 10px;
}
.top-section .form-group, .middle-section .form-group, .bottom-section .form-group{
    margin-bottom: 20px;
}
::v-deep .top-section select, ::v-deep .top-section input, ::v-deep .top-section textarea, ::v-deep .middle-section select, ::v-deep .middle-section input, ::v-deep .middle-section textarea, ::v-deep .bottom-section select, ::v-deep .bottom-section input, ::v-deep .bottom-section textarea{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: auto;
}
.input-group:not(.has-validation) > .form-control:not(:last-child){
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
 /* ::v-deep .middle-section .input-tel__input{
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
} */
/* ::v-deep .middle-section .country-selector__input{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 12px!important;
    border-bottom-left-radius: 12px!important;
}  */
/* ::v-deep .middle-section .country-selector{
    height: 100%;
} */
/* ::v-deep .country-selector.has-value .country-selector__input{
    padding-top:12px;
} */
::v-deep .email-sms-label label{
    font-size:12px; 
    font-weight:325; 
    color:#000 !important;
}
::v-deep .middle-section .custom-control-label{
  margin-bottom:0px;
}
.top-section select{
    padding-left: 65px;  
}
.top-section .flag_images{
    position: absolute;
    width: 28px;
    top: 36px;
    left: 20px;
    border-radius: 4px;
}
#modal-1___BV_modal_outer_ {
  z-index: 9999 !important;
}
.pmt-modal-header-custom{
    border-bottom: none;
    padding-bottom: 0px;
}
.pmt-modal-body-custom{
    padding: 0px 35px 10px 35px;
}
.pmt-modal-footer-custom{
    border-top: none;
    padding: 0px 55px 25px 55px;
}
</style>